import stripUndefined from "lib/utils/stripUndefined";
import get from "lodash/fp/get";
import type { ParsedUrlQuery } from "querystring";

// UTM parameters. See: https://en.wikipedia.org/wiki/UTM_parameters
const UTM_SOURCE = "utm_source";
const UTM_MEDIUM = "utm_medium";
const UTM_CAMPAIGN = "utm_campaign";
const UTM_TERM = "utm_term";
const UTM_CONTENT = "utm_content";

export type UTMParameters = {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
};

export function getUtmParametersFromUrl(rawUrl: string): UTMParameters {
  const url = new URL(rawUrl);

  return stripUndefined({
    utmSource: url.searchParams.get(UTM_SOURCE),
    utmMedium: url.searchParams.get(UTM_MEDIUM),
    utmCampaign: url.searchParams.get(UTM_CAMPAIGN),
    utmTerm: url.searchParams.get(UTM_TERM),
    utmContent: url.searchParams.get(UTM_CONTENT),
  });
}

export function getUtmParametersFromParsedUrlQuery(query: ParsedUrlQuery): UTMParameters {
  return stripUndefined({
    utmSource: firstOrNull(get(UTM_SOURCE, query)),
    utmMedium: firstOrNull(get(UTM_MEDIUM, query)),
    utmCampaign: firstOrNull(get(UTM_CAMPAIGN, query)),
    utmTerm: firstOrNull(get(UTM_TERM, query)),
    utmContent: firstOrNull(get(UTM_CONTENT, query)),
  });
}

function firstOrNull(input: string | string[] | null | undefined): string | null {
  if (!input) {
    return null;
  }
  if (Array.isArray(input)) {
    return input[0] || null;
  }
  return input;
}
