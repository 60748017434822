/* eslint-disable no-underscore-dangle, react/no-danger, import/no-extraneous-dependencies */
import getBaseUrl from "lib/utils/getBaseUrl";
import Head from "next/head";
import { JsonLd } from "react-schemaorg";
import type { Graph } from "schema-dts";

export default function FundamenteiAppHead() {
  const description =
    "Site completo de Análise Fundamentalista de Ações, Stocks e Fundos Imobiliários (FIIs). Indispensável para investidores Buy&Hold!";
  const graph: Graph = {
    "@context": "https://schema.org",
    "@graph": [
      // {
      //   "@type": "WebPage",
      //   "@id": getBaseUrl(),
      //   url: getBaseUrl(),
      //   name: "Fundamentei",
      //   description,
      //   datePublished: startOfDay(parseISO("2019-10-01")).toISOString(),
      //   dateModified: startOfDay(new Date()).toISOString(),
      //   inLanguage: "pt-BR",
      //   potentialAction: {
      //     "@type": "ReadAction",
      //     target: [getBaseUrl()],
      //   },
      // },
      // {
      //   "@type": "BreadcrumbList",
      //   "@id": `${getBaseUrl()}/#breadcrumb`,
      //   itemListElement: [{ "@type": "ListItem", position: 1, name: "Home" }],
      // },
      {
        "@type": "WebSite",
        "@id": `${getBaseUrl()}/#website`,
        url: getBaseUrl(),
        name: "Fundamentei",
        description,
        inLanguage: "pt-BR",
        publisher: {
          "@id": `${getBaseUrl()}/#organization`,
        },
      },
      {
        "@type": "Organization",
        "@id": `${getBaseUrl()}/#organization`,
        name: "Fundamentei",
        url: getBaseUrl(),
        sameAs: [
          "https://instagram.com/fundamentei",
          "https://twitter.com/fundamentei",
          "https://medium.com/@fundamentei",
          "https://github.com/fundamentei",
          "https://www.youtube.com/channel/UC72WcGU4HpVposjNHlP3FRg",
          "https://www.linkedin.com/company/fundamentei",
        ],
        contactPoint: [
          {
            "@type": "ContactPoint",
            email: "contato@fundamentei.com",
            url: getBaseUrl(),
            contactType: "customer support",
            areaServed: ["BR"],
            availableLanguage: ["Portuguese", "English"],
          },
        ],
      },
    ],
  };

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" key="viewport" />
      <meta name="robots" content="index,follow" />

      <JsonLd space={2} item={graph} />
    </Head>
  );
}
