import type { IFinancialsTemplatesQueryFilters } from "types/graphqlTypes";
import { IFinancialsTemplateNamespace } from "types/graphqlTypes";
import type { TypedTypePolicies } from "types/typePolicies";

export const typePolicies: TypedTypePolicies = {
  FinancialsTemplate: {
    keyFields: ["objectId"],
  },
  FinancialsSheetIndicator: {
    keyFields: () => undefined,
  },
  ForumPost: {
    fields: {
      replies: {
        keyArgs: ["@connection", ["key"]],
        merge: (existing, incoming) => {
          if (!existing) {
            return incoming;
          }

          return {
            ...incoming,
            nodes: [...existing.nodes, ...incoming.nodes],
          };
        },
      },
    },
  },
  Query: {
    fields: {
      financialsTemplates: {
        keyArgs: (_, context) => {
          const filters: IFinancialsTemplatesQueryFilters = context?.variables?.filters || {};
          const isPublicTemplates =
            filters.filterByViewerId === null &&
            filters.filterByNamespace?.includes(IFinancialsTemplateNamespace.User) &&
            filters.filterByNamespace?.includes(IFinancialsTemplateNamespace.Fundamentei);

          const hash = [filters.filterByPlatformVertical, filters.filterByTemplateCodes?.join(":")].join("/");
          if (isPublicTemplates) {
            return `PublicFinancialsTemplates:${hash}`;
          }
          if (filters.filterByViewerId) {
            return `UserFinancialsTemplates:${filters.filterByViewerId}:${hash}`;
          }

          return undefined;
        },
        merge: (existing, incoming) => {
          if (!existing) {
            return incoming;
          }

          return {
            ...incoming,
            nodes: [...existing.nodes, ...incoming.nodes],
          };
        },
      },
      posts: {
        keyArgs: ["@connection", ["key"], "contextKey", "contextType", "filterBy", "orderBy"],
        merge: (existing, incoming) => {
          if (!existing) {
            return incoming;
          }

          return {
            ...incoming,
            nodes: [...existing.nodes, ...incoming.nodes],
          };
        },
      },
    },
  },
};
