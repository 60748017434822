/* eslint-disable react/jsx-no-constructed-context-values */
import type { ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Query } from "@apollo/client/react/components";
import { jwtDecode } from "jwt-decode";
import noop from "lodash/fp/noop";
import type { NextPageContext } from "next";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import type { ReactElement } from "react";
import { createContext, useState } from "react";

import nextTick from "lib/utils/nextTick";
import stripUndefined from "lib/utils/stripUndefined";

import { useSetAtom } from "jotai";
import { userSettingsAtom } from "components/settings/atoms";
import { useAmplitude } from "../../lib/amplitude/Amplitude";
import Selfie from "../../lib/queries/SelfieQuery";
import type { ISelfieQuery } from "../../lib/queries/__generated__/SelfieQuery.generated";

export interface IAuthContext {
  loading: boolean;
  user: ISelfieQuery["selfie"] | null;
  token: string | null;
  login(token: string): void;
  logout(): void;
  refetch(): void;
}

export const AuthContext = createContext<IAuthContext>({
  loading: false,
  user: null,
  token: null,
  login: noop,
  logout: noop,
  refetch: noop,
});

interface IAuthState {
  user: ISelfieQuery["selfie"] | null;
  token: string | null;
  login(token: string): void;
  logout(): void;
}

interface ICookies {
  token?: string;
}

interface IAuthConsumerProps {
  children(state: IAuthState): ReactElement | null;
}

const COOKIE_OPTIONS = {
  // 21 days
  maxAge: 21 * 24 * 60 * 60,
  path: "/",
};

function getToken(ctx: NextPageContext | undefined | null): string | null {
  const cookies = parseCookies(ctx);

  if (cookies && cookies.token) {
    const decoded: {
      exp: number;
      nbf: number;
    } = jwtDecode(cookies.token);

    const now = Date.now().valueOf() / 1000;

    const isExpired =
      (typeof decoded.exp !== "undefined" && decoded.exp < now) ||
      (typeof decoded.nbf !== "undefined" && decoded.nbf > now);

    if (!isExpired) {
      return cookies.token;
    }

    // unwantedly it adds a side-effect into this function...however, it ensures that we won't have an expired token
    // hanging around
    logout(ctx);
  }

  return null;
}

function login(token: string): void {
  setCookie(null, "token", token, COOKIE_OPTIONS);
}

function logout(ctx: NextPageContext | undefined | null): void {
  destroyCookie(ctx, "token", COOKIE_OPTIONS);
}

export function getRequestHeaders(ctx: NextPageContext | undefined | null) {
  const token = getToken(ctx);
  const cookies = parseCookies(ctx);

  if (token) {
    return stripUndefined({
      Authorization: `Bearer ${token}`,
      "X-Fundamentei-Plan-Override":
        // eslint-disable-next-line no-underscore-dangle
        process.env.FUNDAMENTEI_ENV === "production" ? null : cookies.__FUNDAMENTEI_PLAN_OVERRIDE,
    });
  }

  return {};
}

export const setAuthorization = (ctx: NextPageContext | undefined | null): ApolloLink => {
  return setContext(async (_, { headers }) => {
    await nextTick();

    return {
      headers: {
        ...headers,
        ...getRequestHeaders(ctx),
      },
    };
  });
};

interface IAuthProviderProps {
  cookies: ICookies;
  children: ReactElement | null;
}

export function AuthProvider({ cookies = {}, children }: IAuthProviderProps) {
  const amplitude = useAmplitude();
  const [hasToken, setHasToken] = useState<boolean>(!!cookies.token);
  const loadSettings = useSetAtom(userSettingsAtom);

  return (
    <Query<ISelfieQuery>
      query={Selfie}
      skip={!hasToken}
      onCompleted={(data) => {
        // if there's no token set ignore this completely
        if (!hasToken) {
          return;
        }

        if (data && data.selfie) {
          const { selfie } = data;

          loadSettings(selfie.settingsV2);
          amplitude.setUserId(selfie._id);

          amplitude.setUserId(selfie._id);
          amplitude.setUserProperties({
            _id: selfie._id,
            email: selfie.email,
            firstName: selfie.firstName,
            lastName: selfie.lastName,
            fullName: selfie.fullName,
            // eslint-disable-next-line no-nested-ternary
            plan: selfie.isBlack ? "BLACK" : selfie.isPremium ? "PREMIUM" : "FREE",
          });
        }
      }}
    >
      {({ loading, data, refetch, updateQuery }) => {
        let user = null;

        if (hasToken) {
          user = data === undefined ? null : data.selfie;
        }

        if (loading && !user) {
          return null;
        }

        return (
          <AuthContext.Provider
            value={{
              user,
              loading,
              token: cookies.token || null,
              refetch: () => refetch(),
              login: (token: string): void => {
                login(token);
                setHasToken(true);
                refetch();
              },
              logout: (): void => {
                amplitude.logEvent("Signed out");

                // https://help.amplitude.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference#methods
                amplitude.setUserId(null);
                amplitude.regenerateDeviceId();
                amplitude.clearUserProperties();
                amplitude.resetSessionId();

                logout(null);
                setHasToken(false);

                updateQuery(() => {
                  return {
                    __typename: "Query",
                    selfie: null,
                  };
                });
              },
            }}
          >
            {children}
          </AuthContext.Provider>
        );
      }}
    </Query>
  );
}

export default function Auth({ children }: IAuthConsumerProps): ReactElement | null {
  return <AuthContext.Consumer>{children}</AuthContext.Consumer>;
}
